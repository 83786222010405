'use strict';

(function () {
    var App = {
        openBox: function openBox() {
            var $box = $('.js-open-box');

            $('body').on('click', function (e) {
                var $thisMobileBox = $(e.target).closest('.mobile-auth-js-open-box-btn');
                var $thisBox = $(e.target).closest('.js-open-box');

                if ($(e.target).closest('.js-open-box-btn').length || $(e.target).closest('.mobile-auth-js-open-box-btn').length) {

                    if ($(e.target).parents('.header__bottom').length > 0 && $(e.target).parent().find('.icon-search').length === 0 || $(e.target).closest('.mobile-auth-js-open-box-btn').length) {

                        $('.js-menu-close').trigger('click');
                        $('.header__body .header-profile.js-open-box').addClass('opened');
                        if ($(e.target).closest('.mobile-auth-js-open-box-btn')) $(".js-scroll-to").trigger('click');

                        return;
                    }

                    if ($thisBox.hasClass('opened')) {
                        $thisBox.removeClass('opened');
                    } else {
                        $box.removeClass('opened');
                        $thisBox.addClass('opened');
                    }

                    return;
                }
                /*-- for only mobile view & hear button--*/
                if ($(e.target).closest('.mobile-js-open-box-btn').length) {

                    if ($(e.target).parents('.header__bottom').length > 0 && $(e.target).parent().find('.icon-search').length === 0) {
                        $('.js-menu-close').trigger('click');
                        $('.header__body .header-left-site.js-open-box').addClass('opened');
                        return;
                    }

                    if ($thisBox.hasClass('opened')) {
                        $thisBox.removeClass('opened');
                    } else {
                        $box.removeClass('opened');
                        $thisBox.addClass('opened');
                    }

                    return;
                }

                if (!$thisBox.length && !$thisMobileBox.length) {
                    $box.removeClass('opened');
                }
            });
        },
        siteMap: function siteMap() {
            var $siteMapBox = $('.js-site-map');
            $('body').on('click', '.js-site-map-btn', function () {
                $siteMapBox.toggleClass('opened');
            });
        },
        menu: function menu() {
            var $body = $('body');

            $body.on('click', function (e) {
                if ($(e.target).closest('.js-menu-open').length) {
                    $body.addClass('menu-opened');
                } else {
                    if (!$(e.target).closest('.nav').length) {
                        $body.removeClass('menu-opened');
                    }
                }

                if ($(e.target).closest('.js-menu-close').length) {
                    $body.removeClass('menu-opened');
                }
            });
        },
        subMenu: function subMenu() {
            var $title = $('.sub-menu__title');

            $('body').on('click', '.sub-menu__title', function () {
                if ($(this).hasClass('opened')) {
                    $(this).removeClass('opened');
                    $(this).next().slideUp();
                } else {
                    $title.removeClass('opened');
                    $title.next().slideUp();
                    $(this).addClass('opened');
                    $(this).next().slideDown();
                }
            });
        },


        goToLink: function goToLink() {
            $(".js-scroll-to").click(function () {
                var blockId = $(this).attr('href');
                var blockOffset = $(blockId).offset().top;

                $('html, body').animate({
                    scrollTop: blockOffset
                }, 600);

                return false;
            });
        },

        extendSearch: function extendSearch() {
            $('.js-extend-search-btn').on('click', function () {
                if ($(this).hasClass('active')) {
                    $(this).removeClass('active');
                    $('.js-extend-search-box').slideUp();
                } else {
                    $(this).addClass('active');
                    $('.js-extend-search-box').slideDown();
                }
            });
        },
        availability: function availability() {
            var $body = $('body');
            var $box = $('.js-availability-box');

            var minSize = +$box.data('size-min');
            var maxSize = +$box.data('size-max');
            var step = +$box.data('size-step');

            $body.on('click', '.js-availability-btn', function () {
                if ($body.hasClass('lowvision')) {
                    $body.removeClass('lowvision').removeAttr("style");
                    $box.removeClass('opened');
                    $('.js-availability-btn').removeClass('active');
                    $('.lowvision-font').removeAttr("style").removeClass('lowvision-resized');
                } else {
                    $body.addClass('lowvision');
                    $('.lowvision-font').addClass('lowvision-resized')
                    $box.addClass('opened');
                    $('.js-availability-btn').addClass('active');
                }

                $body.trigger('availability-changed', { enabled: $body.hasClass('lowvision') });
            });

            $body.on('click', '.js-availability-minus', function () {
                var currentSize = parseInt($body.css('font-size'));

                $('.js-availability-plus').removeClass('disabled');

                if (currentSize > minSize) {
                    $body.css("fontSize", currentSize - step);

                    $('.lowvision-font').each(function(){
                        $(this).css('fontSize', parseInt($(this).css('font-size')) - step);
                    })

                    $body.trigger('availability-changed', { fontSize: currentSize - step });
                }

                if (currentSize - step <= minSize) {
                    $('.js-availability-minus').addClass('disabled');
                }
            });

            $body.on('click', '.js-availability-plus', function () {
                var currentSize = parseInt($body.css('font-size'));

                $('.js-availability-minus').removeClass('disabled');

                if (currentSize < maxSize) {
                    $body.css("fontSize", currentSize + step);

                    $('.lowvision-font').each(function(){
                        $(this).css('fontSize', parseInt($(this).css('font-size')) + step);
                    })

                    $body.trigger('availability-changed', { fontSize: currentSize + step });
                }
                if (currentSize + step >= maxSize) {
                    $('.js-availability-plus').addClass('disabled');
                }
            });
        },
        acco: function acco() {
            var $title = $('.sub-menu__title');

            $('body').on('click', '.js-acco', function () {
                if ($(this).hasClass('opened')) {
                    $(this).removeClass('opened');
                    $(this).next().slideUp();
                } else {
                    $title.removeClass('opened');
                    $title.next().slideUp();
                    $(this).addClass('opened');
                    $(this).next().slideDown();
                }
            });
        },
        fixedMenu: function fixedMenu() {
            var header = $('header.header');
            var fixedHeader = $('#fixed.header');

            fixedHeader.append(header.find('.header__bottom .header__nav').clone()).html();
            fixedHeader.append(header.find('.header-availability.js-availability-box').clone()).html();
            // fixedHeader.append(header.find('.header-availability__head').clone()).html();
            // fixedHeader.append(header.find('.header-availability__body').clone()).html();

            $(window).on('scroll', function () {

                if ($(window).scrollTop() > header.height()) {
                    fixedHeader.removeClass('hidden');
                } else {
                    if (!fixedHeader.hasClass('hidden')) {
                        fixedHeader.addClass('hidden');
                    }
                }
            });
        },


        init: function init() {
            this.openBox();
            this.siteMap();
            this.subMenu();
            this.menu();
            this.goToLink();
            this.extendSearch();
            this.availability();
            this.acco();
            this.fixedMenu();

            $('.js-dropdown-box').each(function () {
                $(this).dropdown({
                    prefix: $(this).data('prefix')
                });
            });
        }
    };

    window.addEventListener('load', function () {
       /* window.svg4everybody();*/
        App.init();

        document.documentElement.addEventListener('touchstart', function (event) {
            if (event.touches.length > 1) {
                event.preventDefault();
            }
        }, false);

        $('body').on('availability-changed', function (e, data) {});
    });
})();
"use strict";

(function ($) {
    var circles = [];
    var coordinates = [];
    var send_values = [];
    var regions = [];
    var reply_values = [];
    var circle_color = [];

    function onMapZoom(map) {
        for (var i = 0; i < circles.length; i++) {
            circles[i].updateRadius(map.getZoom() + 15);
        }
        $(".section-map__popup").css("marginLeft", map.getZoom() + 15 + "px");
    }

    function createCircle(circle_number, reply_data, send_data) {

        var _maxValue = send_data;
        _maxValue = send_data + reply_data;

        return Circles.create({
            id: "circles-" + circle_number,
            radius: 26,
            value: reply_data,
            maxValue: _maxValue,
            width: 8,
            text: send_data + reply_data + '',
            colors: send_data + reply_data ? send_data >= reply_data ? ['#155c85', '#fee162'] : ['#155c85', '#fee162'] : [],

            duration: 500,
            wrpClass: 'circles-wrp',
            textClass: 'circles-text',
            valueStrokeClass: 'circles-valueStroke',
            maxValueStrokeClass: 'circles-maxValueStroke',
            styleWrapper: true,
            styleText: true
        });
    }

    function createIcon(circle_number) {
        return L.divIcon({
            className: 'current-location-icon',
            html: "<div class=\"circle\" id=\"circles-" + circle_number + "\"/>",
            iconAnchor: [0, 0],
            iconSize: null,
            popupAnchor: [0, 0]
        });
    }

    function initMap(domElement) {
        var zoomParam = 6;
        window.isMobileLayout() ? zoomParam = 5 : null;
        var map = L.map(domElement, {
            zoomControl: true,
            scrollWheelZoom: false,
            center: L.latLng(48.4, 31.764),
            maxBounds: [L.latLng(43, 23), L.latLng(53, 40)],
            zoom: zoomParam
        });

        L.tileLayer('https://b.tile.openstreetmap.org/{z}/{x}/{y}.png?lang=uk', {}).addTo(map);

        return map;
    }

    function printDiagrams($map) {
        var map = initMap($map.get(0));

        for (var i = 0; i < coordinates.length; i++) {
            var reply_data = reply_values[i].data;
            var send_data = send_values[i].data;
            var lat = coordinates[i].lat;
            var lng = coordinates[i].lng;
            var send_value = send_values[i].data;
            var reply_value = reply_values[i].data;
            var region_name = regions[i].region;

            var icon = createIcon(i);
            var marker = L.marker([lat, lng], { icon: icon }).addTo(map);

            var offset = lng < 26 ? [28, 24] : [-17, 24];
            var direction = lng < 26 ? 'right' : 'left';
            offset = lat < 45 ? [5, 5] : offset;
            direction = lat < 45 ? 'top' : direction;

            if (window.isMobileLayout()) {
                marker.on({
                    click: function click() {
                        this.openTooltip();
                    }
                });
            }

            var tooltip = L.tooltip({
                offset: offset,
                direction: direction,
                className: 'section-map__popup'
            });

            tooltip.setContent("\n\t\t\t<div style='padding:10px' ><div class=\"section-map-popup-region-label\">" + region_name + "</div>\n\t\t\t<div><span class=\"section-map-popup-label\">\u041F\u0435\u0440\u0435\u0434\u0430\u043D\u043E \u0434\u043E \u043E\u0440\u0433\u0430\u043D\u0456\u0432 \u0432\u0438\u043A\u043E\u043D\u0430\u0432\u0447\u043E\u0457 \u0432\u043B\u0430\u0434\u0438</span> - <b>" + send_value + "</b></div>\n\t\t\t<div><span class=\"section-map-popup-label\">\u041D\u0430\u0434\u0430\u043D\u043E \u043A\u043E\u043D\u0441\u0443\u043B\u044C\u0442\u0430\u0446\u0456\u0439</span> - <b>" + reply_value + "</b></div>\n         </div>");

            marker.bindTooltip(tooltip);

            var circle = createCircle(i, reply_data, send_data);
            circles.push(circle);
        }

        map.on('zoom', function () {
            onMapZoom(map);
        });
    }

    function init($map) {
        $map.find('.js-region-item').each(function () {
            var lat = parseFloat($(this).data('lat'));
            var lng = parseFloat($(this).data('lng'));
            var send_data = parseInt($(this).data('counter-send'));
            var reply_data = parseInt($(this).data('counter-reply'));
            var region = $(this).data('region-name');

            coordinates.push({
                lat: lat,
                lng: lng
            });

            send_values.push({
                data: send_data
            });

            regions.push({
                region: region
            });

            reply_values.push({
                data: reply_data
            });
        });

        $map.each(function () {
            var send_data_color = $(this).data('color-send');
            var reply_data_color = $(this).data('color-reply');

            circle_color.push({
                send_data_color: send_data_color,
                reply_data_color: reply_data_color
            });
        });
    }

    window.initializeMap = function ($map) {
        init($map);
        printDiagrams($map);
    };

    $(function () {
        var $map = $('#map');
        if ($map.length === 0) {
            return;
        }

        initializeMap($map);
    });
})(jQuery);
